/* Generated by Snapfont(https://getsnapfont.com)
 * Paste this at the top of your root css file
 */
/* Body font */
/* Roboto */
body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
}

$font-family-body: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" ;


/* Heading font */
/* undefined */
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
  font-family: "undefined", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
}

$font-family-heading: "undefined", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" ;



  

h1 {
  font-size: 2em;
}

.product-title {
  font-weight: bold;
  font-size: 1.2em;
}

.product-id {
  font-size: .8em;
  margin: 1em 0;
}

.product-edit-icon {
  opacity: .7;
  position: absolute;
  right: 2.3rem;
  top: .4rem;
}

.product-edit-icon:hover {
  opacity: 1;
}

.navbar, .navbar-end, .navbar-menu, .navbar-start {
  align-items: stretch !important;
  display: flex !important;
}

.navbar-menu {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  display: flex !important;
}

.navbar-end {
  justify-content: flex-end !important;
  margin-left: auto !important;
}

.features {
  padding-bottom: 3em;
}

body {
  visibility: visible !important;
}

.file_checkbox {
  display: inline;
  margin: 5px
}

.register_root {
  height:100vh;
  max-width: 100%;
  background-image: url(https://xtrodes-mediafiles.s3.amazonaws.com/webapp/registration.jpg);
  background-size: cover;
}

.auth form {
  margin-top: 1em;
  max-width: 50%;
}

.buttons {
  margin-left: 1em;
}
.button.is-primary{
  background-color: #C6B9C7;
}
.button.is-primary:hover{
  background-color: #C6B9C7;
}
html{
  /* background:#EAE6ED; */
  background-image: url(https://xtrodes-mediafiles.s3.amazonaws.com/webapp/main_background.jpg);
  background-size: cover;

}
@media screen and (min-width: 992px) {
.flexbox-container{
  display: flex;
  justify-content: center;
  margin-top: 7%;
  height: 700px;
  color:darkslategrey;
  font-weight: 400;

  
}
.uploaded-files{
  
  height: 35%;
  display: inline;
  overflow:auto;
  
}

.json-to-table{
 
  overflow:auto;
  
}


.split-files{
  height: 25%;
   
  overflow-y:scroll;
  
}
#aws-list-item-contents{
  display: inline;
  height: 100%;
  overflow:auto;
}
#aws-list-item-contents-output{
  overflow:auto;
  height: 100%;
}

#upload_EDF{
  height: 10%;
  width: 50%;
  padding: 5%;

}

#dataset-list{
  height: 68%;
  padding-top: 15px;

}

.box{
  position:sticky;
  top:60%;
  display:flex;
  justify-content:left;
}
.item1{
   flex:1;
   display: flex;
   justify-content: left;
   transform: translateX(10px);/*D element Width[if needed]*/
}

#button-split{
  margin: 5%;
  width: 30px;

}

#button-split-confirm{
  width: 30px;
  margin-left: 80px;

}

#dataset-list_inner_recent{
  height: 80%;
  overflow: auto;
}

#recent-container{
  margin:10px;
  padding: 100px;
}


#dataset-list_inner{
  height: 80%;
  overflow: auto;
}
.flexbox-item{
  background-color:rgba(255, 255, 255, 0.884);
  margin: 10px;
  padding: 4%;
  
  display: flex;
  flex-direction: column;
  
  padding-top: 3%;

}

#table-test{
  height: 50%;
  width:auto;
  
}

.table-test{
  height: 60%;
  width:500px

}

.flexbox-item-1,.flexbox-item-2,.flexbox-item-3{
  width: 30%;
  border-color:black;
  border-width: thin;
  border-style: solid;
  border-radius: 3px;
  justify-content: space-between;
  list-style-type: none;
}

#registerTip {
  width: 250px;
}

.button{
  width: 100%;
}
.flexbox-headline{
  text-decoration: underline;
  padding: 3%;
  font-weight: bold;
}
.passwordinstruction{
  color: white;
}

.buttonDeleteDataset{
  cursor:pointer;
  border: none;
  height:50%;

}


.dataset-div{
  width: max-content;
  display: flex;
  height: 25px;
  justify-content: start;
}




.span-dataset-datafile-near-image{
  position:relative;
  left: 10px;
}


.version {
  position: relative;
  right: -100%;
  top: 20%;
  color: white;
}

.first-screen-message{
  background-color: rgba(255, 255, 255, 0.884);
  margin: 10px;
  padding: 4%;
  
  display: block;
  
  padding-top: 3%;
  width: 40%;
  border-color: white;
  border-width: thin;
  border-style: solid;
  border-radius: 3px;
  list-style-type: none;
  color:black;
  margin: auto;
  margin-top: 10%;
  font-size: large;
  
}
.main-display-device{
  display: block;
}
.mobile-alternate-message{
  display: none;
}
}
@media screen and (max-width: 991px) {
  .main-display-device{
    display:none ;
  }
  .mobile-alternate-message{
    display:block ; 
  }
  .first-screen-message{
    background-color: rgba(255, 255, 255, 0.884);
    margin: 10px;
    padding: 4%;
    
    display: block;
    
    padding-top: 3%;
    width: 40%;
    border-color: white;
    border-width: thin;
    border-style: solid;
    border-radius: 3px;
    list-style-type: none;
    color:black;
    margin: auto;
    margin-top: 10%;
    font-size: large;
    
  }
}